interface LanguageProperties {
  active: boolean,
  languageid: number,
  key: string,
  hreflang: string,
  language: string,
  class: string,
  tesseract: string,
  dayjs: string
}

const Languages: Array<LanguageProperties> = [
  {
    active: true,
    languageid: 0,
    key: 'en',
    hreflang: 'en',
    language: 'English',
    class: 'translationEN',
    tesseract: 'eng',
    dayjs: 'en'
  },
  {
    active: true,
    languageid: 1,
    key: 'es',
    hreflang: 'es',
    language: 'Español',
    class: 'translationES',
    tesseract: 'spa',
    dayjs: 'es'
  },
  {
    active: true,
    languageid: 2,
    key: 'de',
    hreflang: 'de',
    language: 'Deutsch',
    class: 'translationDE',
    tesseract: 'deu',
    dayjs: 'de'
  },
  {
    active: true,
    languageid: 3,
    key: 'it',
    hreflang: 'it',
    language: 'Italiano',
    class: 'translationIT',
    tesseract: 'ita',
    dayjs: 'it'
  },
  {
    active: true,
    languageid: 4,
    key: 'fr',
    hreflang: 'fr',
    language: 'Français',
    class: 'translationFR',
    tesseract: 'fra',
    dayjs: 'fr'
  },
  {
    active: false,
    languageid: 5,
    key: 'zh_CN',
    hreflang: 'zh-Hans',
    language: '中文（简体）',
    class: 'translationZH_CN',
    tesseract: 'chi_sim',
    dayjs: 'zh-cn'
  },
  {
    active: false,
    languageid: 6,
    key: 'zh_TW',
    hreflang: 'zh-Hant',
    language: '中文（繁體）',
    class: 'translationZH_TW',
    tesseract: 'chi_tra',
    dayjs: 'zh-tw'
  },
  {
    active: true,
    languageid: 7,
    key: 'ru',
    hreflang: 'ru',
    language: 'российский',
    class: 'translationRU',
    tesseract: 'rus',
    dayjs: 'ru'
  },
  {
    active: false,
    languageid: 8,
    key: 'hi',
    hreflang: 'hi',
    language: 'हिन्दी भाषा',
    class: 'translationHI',
    tesseract: 'hin',
    dayjs: 'hi'
  }
];


export default Languages;
