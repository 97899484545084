import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { WebSocketLink } from "@apollo/client/link/ws";
import { HttpLink } from "@apollo/client/link/http";
import { getMainDefinition } from "@apollo/client/utilities";
import { ApolloClient, ApolloLink, Observable, split, InMemoryCache, ApolloProvider } from "@apollo/client";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";
import ReactGA from 'react-ga';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { getAccessToken, setAccessToken } from "./accessToken";
import Loading from './layout/components/Loading'

// lazy load components
const ScrollToTop = lazy(() => import('./utils/ScrollToTop'))
const App = lazy(() => import('./App'))


const cache = new InMemoryCache({});

// TODO: Fix this environment variable problem
const wsURL = process.env.REACT_APP_WS_URL
const apiURL = process.env.REACT_APP_API_URL
if (window.location.hostname.startsWith('doctorlingo.com') === true) {
  ReactGA.initialize('UA-39102328-11');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any;
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`
              }
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const httpLink = new HttpLink({
  uri: apiURL + "/graphql",
  credentials: "include"
})

const wsClient = new SubscriptionClient(wsURL + "/graphql", {
  reconnect: true,
  lazy: true
})

// Create a WebSocket link:
const wsLink = new WebSocketLink(wsClient);
interface Definintion {
  kind: string;
  operation?: string;
}
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation }: Definintion = getMainDefinition(query);
    return (
      kind === 'OperationDefinition' &&
      operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: () => {
    const token = getAccessToken();

    if (!token) {
      return true;
    }

    try {
      const jwt: any = jwtDecode(token);
      if (Date.now() >= jwt.exp * 1000) {
        return false;
      } else {
        return true;
      }
    } catch {
      return false;
    }
  },
  fetchAccessToken: () => {
    return fetch(apiURL + "/refresh_token", {
      method: "POST",
      credentials: "include"
    });
  },
  handleFetch: accessToken => {
    setAccessToken(accessToken);
  },
  handleError: err => {
    console.warn("Your refresh token is invalid. Try to relogin");
    console.error(err);
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([
    tokenRefreshLink,
    //onError(({ graphQLErrors, networkError }) => {
    //  console.log(graphQLErrors);
    //  console.log(networkError);
    //}),
    requestLink,
    link
  ]),
  cache
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Suspense fallback={<Loading loading={true} />}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Suspense>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
