import React from 'react';
import { Spin } from 'antd';

const spinnerStyle = {
  position: 'fixed' as 'fixed',
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "black",
  backgroundColor: "transparent",
  padding: "10px",
  fontFamily: "Arial"
};

interface Props {
  loading: boolean
}

const Loading: React.FC<Props> = (props) => {
  return (
    <div style={spinnerStyle}>
      <Spin spinning={props.loading} />
    </div>)
}
export default Loading;